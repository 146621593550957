/**
 * @author Manifold Finance, Inc.
 * @date July, 12, 2023
 * @license GPL-3.0
 */

import '@/styles/globals.css';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { Analytics } from '@vercel/analytics/react';
import { atom, Provider } from 'jotai';
import PlausibleProvider from 'next-plausible';
import type { AppProps, NextWebVitalsMetric } from 'next/app';
import React, { useEffect } from 'react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { goerli, mainnet } from 'wagmi/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';

// FIXME: remove const infura_rpc_url
const INFURA_API_KEY = 'fe24c1ff7cfb4ddabec77f512e60c5b6';
const { chains, publicClient } = configureChains(
  [goerli, mainnet],
  //@ts-ignore
  [infuraProvider({ apiKey: INFURA_API_KEY }), publicProvider()],
);
export const stakedAmount = atom('0.0');
export const stakedTxHash = atom('');
export const stakedTxSuccessMessage = atom('');
export const writeTxErrorMessage = atom('');
export const stakedTxStatus = atom<'None' | 'Loading' | 'Success' | 'Error' | 'Idle'>('None');

// TODO: add chains and correct project id
const { connectors } = getDefaultWallets({
  appName: 'meveth',
  projectId: 'manifold-13990fbed26c63d0a8e72e3c5e2eaf2d',
  chains: [],
});

const wagmiConfig = createConfig({
  publicClient,
  connectors,
});
/**
 * @config next-plausible
 * @const NEXT_PUBLIC_DOMAIN
 * @returns 'manifoldfinance.com'
 */

// export const NEXT_PUBLIC_DOMAIN = 'testing-meveth.vercel.app';
// export const NEXT_PUBLIC_DOMAIN = 'meveth.xyz';
export const NEXT_PUBLIC_DOMAIN = 'meveth.xyz';

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.log(metric);
}
export default function App({ Component, pageProps }: AppProps) {
  // style={{ scrollBehavior: "smooth" }}
  // add this to the body tag after the page loads
  // to make the page scroll smoothly
  // https://stackoverflow.com/questions/36904185/react-router-scroll-to-top-on-every-transition

  useEffect(() => {
    document.body.style.scrollBehavior = 'smooth';
    const html = document.querySelector('html');
    if (html) {
      html.style.scrollBehavior = 'smooth';
    }
  }, []);

  return (
    // @ts-ignore
    <PlausibleProvider domain={process.env.NEXT_PUBLIC_DOMAIN}>
      <WagmiConfig config={wagmiConfig}>
        <RainbowKitProvider chains={chains}>
          <Provider>
            <Component {...pageProps} />
            <Analytics />
          </Provider>
        </RainbowKitProvider>
      </WagmiConfig>
    </PlausibleProvider>
  );
}
